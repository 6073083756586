exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-1-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/blog-1/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-1-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-2-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/blog-2.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-2-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-3-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/blog-3.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-3-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-4-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/blog-4.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-4-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-5-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/blog-5.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-blog-5-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-elephants-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/elephants/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-elephants-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-gatsby-with-cloudflare-pages-and-sveltia-cms-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/gatsby-with-cloudflare-pages-and-sveltia-cms/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-gatsby-with-cloudflare-pages-and-sveltia-cms-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-test-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/posts/test/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posts-test-index-mdx" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

